<template>
  <v-card
    :to="{
      name: 'GalleryImage',
      params: { gallery: gallery, image: image, lazySrc: imageUrl },
    }"
    class="fill-height"
  >
    <v-img :src="imageUrl" contain :aspect-ratio="1 / 1">
      <template v-slot:placeholder>
        <v-row class="fill-height ma-0" align="center" justify="center">
          <v-progress-circular indeterminate color="grey"></v-progress-circular>
        </v-row>
      </template>
    </v-img>
  </v-card>
</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "GalleryThumbLink",
  props: { gallery: String, image: String },
  data() {
    return {
      imageUrl: "",
      loading: false,
    };
  },
  methods: {
    async fetchImage() {
      this.loading = true;
      const url = await this.apiBlob({
        path: `gallery/image?gallery=${this.gallery}&image=${this.image}&thumb`,
      });
      this.loading = false;
      if (url) {
        this.imageUrl = url;
      }
    },
  },
  async created() {
    this.fetchImage();
  },
});
</script>
